.App {
  text-align: center;
  overflow: hidden;
  background-color: black;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App {

  background: linear-gradient(180deg, #000000, #51235c);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 15s ease infinite;
    -moz-animation: AnimationName 15s ease infinite;
    animation: AnimationName 15s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:67% 0%}
    50%{background-position:34% 100%}
    100%{background-position:67% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:67% 0%}
    50%{background-position:34% 100%}
    100%{background-position:67% 0%}
}
@keyframes AnimationName {
    0%{background-position:67% 0%}
    50%{background-position:34% 100%}
    100%{background-position:67% 0%}
}
